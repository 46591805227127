import cn from 'classnames'
import {BadgeDollarSign, DollarSign, LineChart, LucideIcon, User2} from 'lucide-react'
import * as React from 'react'
import {Col, Row} from 'react-bootstrap'
import * as styles from './HeroMetrics.module.scss'
import {Exchange, HeroMetricsResponse} from '~src/api'
import {useCurrentCompany} from '~src/hooks/useCurrentCompany'
import {formatNumber} from '~src/util/formatNumber'

const currencies: Record<Exchange, string> = {
    ASX: 'AUD',
    NZX: 'NZD',
} as const

export const HeroMetrics = () => {
    const company = useCurrentCompany()

    if (!company || company.hero_metrics_list.length === 0) {
        return null
    }

    const metrics = company.hero_metrics_list

    const instrumentCode = (metric: HeroMetricsResponse) =>
        metrics.length > 1 ? `${metric.code}:${metric.exchange}` : metric.code

    return (
        <>
            {metrics.map(metric => (
                <Row key={instrumentCode(metric)}>
                    <HeroMetric
                        label={`Number of ${instrumentCode(metric)} shareholders`}
                        value={formatNumber({number: metric.total_shareholders, decimalPlaces: 0})}
                        icon={User2}
                    />
                    <HeroMetric
                        label={`Total ${instrumentCode(metric)} holdings (${currencies[metric.exchange]})`}
                        value={`$${formatNumber({
                            number: metric.total_holdings,
                            decimalPlaces: 1,
                        })}`}
                        icon={DollarSign}
                    />
                    <HeroMetric
                        label={`Average shareholdings (${currencies[metric.exchange]})`}
                        value={`$${formatNumber({
                            number: metric.average_shareholding,
                            decimalPlaces: 2,
                        })}`}
                        icon={BadgeDollarSign}
                    />
                    <HeroMetric
                        label={`Average buy-in price (${currencies[metric.exchange]})`}
                        value={`$${formatNumber({
                            number: metric.average_buy_in_price,
                            decimalPlaces: 2,
                        })}`}
                        icon={LineChart}
                    />
                </Row>
            ))}
        </>
    )
}

interface HeroMetricProps {
    label: string
    value: string
    icon: LucideIcon
}

const HeroMetric = ({label, value, icon}: HeroMetricProps) => {
    const Icon = icon

    return (
        <Col sm={12} md={6} lg={6} xxl={3} className="mt-3">
            <div className={styles.heroMetric}>
                <div className={styles.heroMetricHeader}>
                    <p className={cn(styles.heroMetricLabel, 'mb-2')}>{label}</p>
                    <Icon size={20} />
                </div>
                <p className="h3 mb-3">{value}</p>
            </div>
        </Col>
    )
}
