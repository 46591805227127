/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface LogoutResponse
 */
export interface LogoutResponse {
    /**
     *
     * @type {string}
     * @memberof LogoutResponse
     */
    id: string
}

export function LogoutResponseFromJSON(json: any): LogoutResponse {
    return LogoutResponseFromJSONTyped(json, false)
}

export function LogoutResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogoutResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
    }
}

export function LogoutResponseToJSON(value?: LogoutResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
    }
}
