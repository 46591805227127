import * as React from 'react'
import {
    Accordion,
    Badge,
    Col,
    Container,
    Form,
    InputGroup,
    Nav,
    NavDropdown,
    Navbar,
    Row,
    Stack,
    Table,
} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import {ButtonWithIcon} from '~src/components/ButtonWithIcon/ButtonWithIcon'
import Modal from '~src/components/Modal/Modal'

export const KitchenSink = () => {
    const [validated, setValidated] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget

        if (form?.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }

        setValidated(true)
    }

    return (
        <div className="container py-5">
            <h1>KitchenSink H1</h1>
            <h1>
                Heading 1
                <br />
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non distinctio animi, explicabo accusamus
                doloribus eos facilis, earum voluptatibus blanditiis error ad aut hic tempore rerum, nisi fugit ipsum
                ipsa aliquam?
            </h1>
            <h2>
                Heading 2
                <br />
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non distinctio animi, explicabo accusamus
                doloribus eos facilis, earum voluptatibus blanditiis error ad aut hic tempore rerum, nisi fugit ipsum
                ipsa aliquam?
            </h2>
            <h3>
                Heading 3
                <br />
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non distinctio animi, explicabo accusamus
                doloribus eos facilis, earum voluptatibus blanditiis error ad aut hic tempore rerum, nisi fugit ipsum
                ipsa aliquam?
            </h3>
            <h4>
                Heading 4
                <br />
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non distinctio animi, explicabo accusamus
                doloribus eos facilis, earum voluptatibus blanditiis error ad aut hic tempore rerum, nisi fugit ipsum
                ipsa aliquam?
            </h4>
            <p>
                Paragraph
                <br />
                Lorem ipsum dolor sit amet, <a href="/kitchen-sink">consectetur adipisicing elit</a>. In dolorum non
                blanditiis quo minus assumenda veniam, a iure repellendus ad fuga eaque ab suscipit architecto quos
                voluptatibus exercitationem eos dicta!
            </p>

            <small>
                Small
                <br />
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolorum non blanditiis quo minus assumenda
                veniam, a iure repellendus ad fuga eaque ab suscipit architecto quos voluptatibus exercitationem eos
                dicta!
            </small>

            <hr />

            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                    <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group>

                <Form.Select className="mb-3" aria-label="Default select example">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </Form.Select>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>

            <hr />

            <Form noValidate validated={validated} onSubmit={evt => handleSubmit(evt)}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>First name</Form.Label>
                        <Form.Control required type="text" placeholder="First name" defaultValue="Mark" />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control required type="text" placeholder="Last name" defaultValue="Otto" />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                        <Form.Label>Username</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Username"
                                aria-describedby="inputGroupPrepend"
                                required
                            />
                            <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="City" required />
                        <Form.Control.Feedback type="invalid">Please provide a valid city.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="State" required />
                        <Form.Control.Feedback type="invalid">Please provide a valid state.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom05">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" placeholder="Zip" required />
                        <Form.Control.Feedback type="invalid">Please provide a valid zip.</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                    <Form.Check
                        required
                        label="Agree to terms and conditions"
                        feedback="You must agree before submitting."
                        feedbackType="invalid"
                    />
                </Form.Group>
                <Button type="submit">Submit form</Button>
            </Form>

            <hr />

            <div className="p-3">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Accordion Item #1</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Accordion Item #2</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Accordion Item #3</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Accordion Item #4</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <hr />

            <div className="bg-white p-3">
                <h4>Custom badges</h4>
                <Stack direction="horizontal" gap={2}>
                    <Badge bg="dark" pill>
                        Dark
                    </Badge>
                    <Badge bg="light" text="dark" pill>
                        Light
                    </Badge>
                    <span className="badge border border-light text-dark rounded-pill">Border light</span>
                </Stack>
            </div>

            <hr />

            <div className="bg-white p-3">
                <h4>Standard bootstrap badges</h4>
                <Stack direction="horizontal" gap={2}>
                    <Badge bg="primary" pill>
                        Primary
                    </Badge>
                    <Badge bg="secondary" pill>
                        Secondary
                    </Badge>
                    <Badge bg="success" pill>
                        Success
                    </Badge>
                    <Badge bg="danger" pill>
                        Danger
                    </Badge>
                    <Badge bg="warning" pill text="dark">
                        Warning
                    </Badge>
                    <Badge bg="info" pill>
                        Info
                    </Badge>
                </Stack>
            </div>

            <hr />

            <Button onClick={() => setShowModal(true)}>Show modal</Button>

            <div className="modal show" style={{display: 'block', position: 'initial'}}>
                <Modal
                    isOpen={showModal}
                    setIsOpen={setShowModal}
                    title="Modal title"
                    buttons={[
                        {label: 'Close', variant: 'ghost'},
                        {
                            label: 'Save changes',
                            variant: 'primary',
                        },
                    ]}
                >
                    <p>Modal body text goes here.</p>
                </Modal>
            </div>

            <hr />

            <div className="p-4 bg-white">
                <Table responsive>
                    <thead>
                        <tr>
                            {['primary', 'secondary', 'outline', 'ghost', 'destructive'].map(variant => (
                                <th key={variant}>{variant}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {['primary', 'secondary', 'outline', 'ghost', 'destructive'].map(variant => (
                                <td key={variant}>
                                    <Button variant={variant}>Normal button</Button>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            {['primary', 'secondary', 'outline', 'ghost', 'destructive'].map(variant => (
                                <td key={variant}>
                                    <Button variant={variant} disabled>
                                        Disabled button
                                    </Button>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            {['primary', 'secondary', 'outline', 'ghost', 'destructive'].map(variant => (
                                <td key={variant}>
                                    <ButtonWithIcon variant={variant} leftIcon="save">
                                        Left icon
                                    </ButtonWithIcon>
                                </td>
                            ))}
                        </tr>
                        <tr>
                            {['primary', 'secondary', 'outline', 'ghost', 'destructive'].map(variant => (
                                <td key={variant}>
                                    <ButtonWithIcon variant={variant} rightIcon="arrowRight">
                                        Right icon
                                    </ButtonWithIcon>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </Table>
            </div>

            <hr />

            <Table responsive hover>
                <thead>
                    <tr>
                        <th>#</th>
                        {Array.from({length: 6}).map((_, index) => (
                            <th key={index}>Table heading</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        {Array.from({length: 6}).map((_, index) => (
                            <td key={index}>Table cell {index}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>2</td>
                        {Array.from({length: 6}).map((_, index) => (
                            <td key={index}>Table cell {index}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>3</td>
                        {Array.from({length: 6}).map((_, index) => (
                            <td key={index}>Table cell {index}</td>
                        ))}
                    </tr>
                </tbody>
            </Table>

            <hr />

            <Table responsive hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Type</th>
                        <th>Date sent</th>
                        <th># of recipients</th>
                        <th>1 week shareholding impact</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Infratil 2022 Investor Day</td>
                        <td>Annual report</td>
                        <td>20/1/2022, 1:10 pm</td>
                        <td>29,012</td>
                        <td>+3.21%</td>
                        <td>
                            <ButtonWithIcon variant="ghost" rightIcon="arrowRight">
                                View details
                            </ButtonWithIcon>
                        </td>
                    </tr>

                    <tr>
                        <td>Infratil 2022 Investor Day</td>
                        <td>Interim results</td>
                        <td>20/1/2022, 1:10 pm</td>
                        <td>29,012</td>
                        <td>+3.21%</td>
                        <td>
                            <ButtonWithIcon variant="ghost" rightIcon="arrowRight">
                                View details
                            </ButtonWithIcon>
                        </td>
                    </tr>

                    <tr>
                        <td>Infratil 2022 Investor Day</td>
                        <td>Notice of AGM</td>
                        <td>20/1/2022, 1:10 pm</td>
                        <td>29,012</td>
                        <td>+3.21%</td>
                        <td>
                            <ButtonWithIcon variant="ghost" rightIcon="arrowRight">
                                View details
                            </ButtonWithIcon>
                        </td>
                    </tr>

                    <tr>
                        <td>Infratil 2022 Investor Day</td>
                        <td>Newsletter</td>
                        <td>20/1/2022, 1:10 pm</td>
                        <td>29,012</td>
                        <td>+3.21%</td>
                        <td>
                            <ButtonWithIcon variant="ghost" rightIcon="arrowRight">
                                View details
                            </ButtonWithIcon>
                        </td>
                    </tr>
                </tbody>
            </Table>

            <hr />

            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#link">Link</Nav.Link>
                            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* END KITCHEN SINK */}
        </div>
    )
}
