import Decimal from 'decimal.js'

export const formatNumberWithThousandsSeparator = (number: string) => {
    const [integer, fraction] = number.split('.')
    const integerWithThousandSeparator = integer.replace(/\B(?=(\d{3})+$)/g, ',')

    return fraction ? `${integerWithThousandSeparator}.${fraction}` : integerWithThousandSeparator
}

export const formatNumber = ({
    number,
    roundDown,
    decimalPlaces = 2,
}: {
    number: string
    roundDown?: boolean
    decimalPlaces?: number
}) => {
    const comparableNumber = Number(number)
    const roundingRule = roundDown ? Decimal.ROUND_DOWN : Decimal.ROUND_HALF_UP

    // to 6 significant digits, round half up
    // e.g. 123,374,287.483 -> 123.374 million
    if (comparableNumber > 1e12) {
        const decimal = new Decimal(number).div(1e12).toFixed(decimalPlaces, roundingRule)
        return `${decimal}t`
    }

    if (comparableNumber > 1e9) {
        const decimal = new Decimal(number).div(1e9).toFixed(decimalPlaces, roundingRule)
        return `${decimal}b`
    }

    if (comparableNumber > 1e6) {
        const decimal = new Decimal(number).div(1e6).toFixed(decimalPlaces, roundingRule)
        return `${decimal}m`
    }

    // number < 1e6
    const decimal = new Decimal(number).toFixed(decimalPlaces, roundingRule)
    return formatNumberWithThousandsSeparator(decimal.toString())
}
