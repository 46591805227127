/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum InvestorCommunicationState {
    DRAFT = 'DRAFT',
    PENDING_REVIEW = 'PENDING_REVIEW',
    APPROVED = 'APPROVED',
    SENT = 'SENT',
    DELETED = 'DELETED',
}

export function InvestorCommunicationStateFromJSON(json: any): InvestorCommunicationState {
    return InvestorCommunicationStateFromJSONTyped(json, false)
}

export function InvestorCommunicationStateFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InvestorCommunicationState {
    return json as InvestorCommunicationState
}

export function InvestorCommunicationStateToJSON(value?: InvestorCommunicationState | null): any {
    return value as any
}
