@import '/src/_assets/styles/config.module';
@import '/src/_assets/styles/typography';

.heroMetric {
    @include cardContainer;
    min-width: 250px;

    .heroMetricHeader {
        display: flex;
        gap: 8px;
        align-items: flex-start;
        align-self: stretch;
        justify-content: space-between;
        padding-top: 24px;

        p {
            @include typography(small-text);
        }
    }

    .heroMetricLabel {
        $textDecorationColor: #e4e4e7;
        text-decoration: underline;
        text-underline-offset: 5px;
        text-decoration-color: $textDecorationColor;
    }
}
