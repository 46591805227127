/* tslint:disable */
/* eslint-disable */
import {Configuration} from '../'
import {DefaultApi} from './DefaultApi'

export * from './DefaultApi'

export const defaultApi = new DefaultApi(
    new Configuration({
        basePath: '',
    }),
)

export const companyPortalApi = {
    defaultApi,
}

export default companyPortalApi
