import {useQueryClient} from '@tanstack/react-query'
import computedStyleToInlineStyle from 'computed-style-to-inline-style'
import {Formik, FormikProps} from 'formik'
import {DateTime} from 'luxon'
import * as React from 'react'
import {Form, Col, Row} from 'react-bootstrap'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {useNavigate} from 'react-router-dom'
import {Editor as TinyMceEditor} from 'tinymce'
import {InvestorCommunicationResponse} from '~src/api'
import Editor from '~src/components/Editor/Editor'
import {PineappleLoader} from '~src/components/PineappleLoader/PineappleLoader'
import {DatePicker} from '~src/components/SingleDatePicker/SingleDatePicker'
import {TIME_SELECT_OPTIONS, TimeSelect} from '~src/components/TimeSelect/TimeSelect'
import {useCreateCommunication, useUpdateCommunication} from '~src/hooks/communications'

interface FormProps {
    forwardedRef: React.RefObject<FormikProps<FormValues>> | undefined
    existingComm?: InvestorCommunicationResponse
    existingCommIsDraft: boolean
}

export interface FormValues {
    subject: string
    scheduleSendAt: DateTime | undefined
    scheduleSendTime: string | undefined
    content: string
}

const ELEMENTS_FOR_INLINE_STYLES = ['a', 'table', 'tr', 'td', 'p']
const PROPERTIES_FOR_INLINE_STYLES = ['color', 'border', 'background-color', 'font-weight', 'padding', 'margin']

export const CommunicationForm = ({forwardedRef, existingComm, existingCommIsDraft}: FormProps) => {
    const editorRef = React.useRef<TinyMceEditor | null>(null)

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const createCommunication = useCreateCommunication({
        onSuccess: data => {
            queryClient.invalidateQueries({queryKey: ['company']})
            // if we haven't navigated away from the new comm page, redirect to edit
            if (location.pathname.includes('/new')) {
                navigate(`/communications/${data.id}/edit`, {replace: true})
            }
        },
    })
    const updateCommunication = useUpdateCommunication({
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['company']})
        },
    })

    // transform content styles to inline, this is the only way they can be saved with the content
    const applyInlineStyles = () => {
        ELEMENTS_FOR_INLINE_STYLES.forEach(elementType => {
            const elements = editorRef.current!.dom.select(elementType)
            for (const e of elements) {
                computedStyleToInlineStyle(e, {
                    recursive: true,
                    properties: PROPERTIES_FOR_INLINE_STYLES,
                })
            }
        })

        return editorRef.current!.getContent()
    }

    const setTimeOnSendAt = (scheduleSendAt: DateTime, scheduleSendTime: string) => {
        const selectedTime = scheduleSendTime.split(':')

        if (selectedTime.length === 2) {
            return scheduleSendAt.toLocal().set({
                hour: parseInt(selectedTime[0], 10),
                minute: parseInt(selectedTime[1], 10),
                second: 0,
            })
        }

        return scheduleSendAt
    }

    const onSubmit = (values: FormValues) => {
        values.content = applyInlineStyles()

        values.scheduleSendAt = setTimeOnSendAt(values.scheduleSendAt!, values.scheduleSendTime!)

        const commonMutateData = {
            subject: values.subject,
            content: values.content,
            schedule_send_at: values.scheduleSendAt!,
        }
        if (!existingComm) {
            createCommunication.mutate(commonMutateData)
        } else {
            updateCommunication.mutate({
                communicationId: existingComm.id,
                data: commonMutateData,
            })
        }
    }

    // wait for the existing comm to load before initialising the form
    if (location.pathname.includes('/edit') && !existingComm) {
        return <PineappleLoader fullScreen />
    }

    return (
        <Formik
            enableReinitialize
            innerRef={forwardedRef}
            initialValues={{
                subject: existingComm?.title || '',
                scheduleSendAt: existingComm?.schedule_send_at || undefined,
                scheduleSendTime: existingComm ? existingComm.schedule_send_at.toLocal().toFormat('HH:mm') : undefined,
                content: existingComm?.content || '',
            }}
            onSubmit={(values, actions) => {
                onSubmit(values)
                actions.setSubmitting(false)
            }}
            validate={values => {
                const errors: {[key: string]: string} = {}

                if (values.subject.trim().length === 0) {
                    errors.subject = 'Message subject is required'
                }

                if (!values.scheduleSendAt) {
                    errors.scheduleSendAt = 'Schedule date is required'
                }
                if (!values.scheduleSendAt || values.scheduleSendTime?.trim().length === 0) {
                    errors.scheduleSendTime = 'Schedule time is required'
                }

                if (!values.content) {
                    errors.content = 'Message content is required'
                }
                return errors
            }}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {props => (
                <Form noValidate onSubmit={props.handleSubmit} className="d-flex flex-column gap-3 mb-3">
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>Subject</Form.Label>
                                <Form.Control
                                    name="subject"
                                    type="text"
                                    value={props.values.subject}
                                    onChange={props.handleChange}
                                    maxLength={60}
                                    disabled={props.isSubmitting || !existingCommIsDraft}
                                />
                                <Form.Text>
                                    This will be the subject line on the email and the title of email content
                                </Form.Text>
                                {/* Done this way because the two below do not show a red border and we prefer consistency */}
                                {props.errors.subject && (
                                    <div className="custom-field-invalid-feedback">{props.errors.subject}</div>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group>
                        <Row>
                            <Form.Label>Date and time for send</Form.Label>
                        </Row>
                        <Row>
                            <Col className="mb-1 pe-0 col-auto">
                                <DatePicker
                                    value={props.values.scheduleSendAt}
                                    onChange={value => {
                                        props.setFieldValue('scheduleSendAt', DateTime.fromMillis(value.valueOf()))
                                    }}
                                    disabled={props.isSubmitting || !existingCommIsDraft}
                                />
                                {props.errors.scheduleSendAt && (
                                    <div className="custom-field-invalid-feedback">{props.errors.scheduleSendAt}</div>
                                )}
                            </Col>
                            <Col className="mb-1">
                                <TimeSelect
                                    defaultValue={props.values.scheduleSendTime}
                                    onChange={value => {
                                        props.setFieldValue('scheduleSendTime', value)
                                    }}
                                    disabled={props.isSubmitting || !existingCommIsDraft}
                                />
                                {props.errors.scheduleSendTime && (
                                    <div className="custom-field-invalid-feedback">{props.errors.scheduleSendTime}</div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {props.values.scheduleSendTime && props.values.scheduleSendAt && (
                                    <Form.Text>
                                        Your message will start sending at{' '}
                                        {
                                            TIME_SELECT_OPTIONS.find(o => o.value === props.values.scheduleSendTime)!
                                                .label
                                        }{' '}
                                        (NZDT) on{' '}
                                        {props.values.scheduleSendAt.setZone('Pacific/Auckland').toFormat('dd LLLL y')}{' '}
                                        to all recipients. Keep in mind some recipients might be in different time
                                        zones.
                                    </Form.Text>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>Body</Form.Label>
                                <Editor
                                    value={props.values.content}
                                    forwardedRef={editorRef}
                                    disabled={props.isSubmitting || !existingCommIsDraft}
                                    onChange={value => props.setFieldValue('content', value)}
                                />
                                {props.errors.content && (
                                    <div className="custom-field-invalid-feedback">{props.errors.content}</div>
                                )}
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            )}
        </Formik>
    )
}
