import {useRollbar} from '@rollbar/react'
import {useMutation, useQuery} from '@tanstack/react-query'
import {InvestorCommunicationResponse} from '~src/api'
import {
    CreateCommunicationApiCommunicationsPostRequest as CreateCommunicationRequest,
    UpdateCommunicationApiCommunicationsCommunicationIdPutRequest as UpdateCommunicationRequest,
    DeleteCommunicationApiCommunicationsCommunicationIdDeleteRequest as DeleteCommunicationRequest,
    SendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPutRequest as SubmitCommunicationRequest,
    SendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPostRequest as PreviewCommunicationRequest,
    defaultApi as api,
} from '~src/api/apis'
import {sendError} from '~src/rollbar'

interface UseCreateOrUpdateCommunicationProps {
    onSuccess?: (data: InvestorCommunicationResponse) => void
}

export const useCreateCommunication = ({onSuccess}: UseCreateOrUpdateCommunicationProps) => {
    const rollbar = useRollbar()
    return useMutation({
        mutationFn: (
            createOrUpdateInvestorCommunicationRequest: CreateCommunicationRequest['createOrUpdateInvestorCommunicationRequest'],
        ) => {
            return api.createCommunicationApiCommunicationsPost({
                createOrUpdateInvestorCommunicationRequest,
            })
        },
        onSuccess: data => onSuccess && onSuccess(data),
        onError: (error, variables, context) => {
            sendError(rollbar, 'Error creating communication via api post: ', [error, variables, context])
        },
    })
}

interface UpdateCommunicationRequestProps {
    communicationId: string
    data: UpdateCommunicationRequest['createOrUpdateInvestorCommunicationRequest']
}

export const useUpdateCommunication = ({onSuccess}: UseCreateOrUpdateCommunicationProps) => {
    const rollbar = useRollbar()

    return useMutation({
        mutationFn: (requestData: UpdateCommunicationRequestProps) => {
            return api.updateCommunicationApiCommunicationsCommunicationIdPut({
                communicationId: requestData.communicationId,
                createOrUpdateInvestorCommunicationRequest: requestData.data,
            })
        },
        onSuccess: data => onSuccess && onSuccess(data),
        onError: (error, variables, context) => {
            sendError(rollbar, 'Error updating communication via api put: ', [error, variables, context])
        },
    })
}

export const useDeleteCommunication = ({onSuccess}: {onSuccess: () => void}) => {
    const rollbar = useRollbar()

    return useMutation({
        mutationFn: (requestData: DeleteCommunicationRequest) => {
            return api.deleteCommunicationApiCommunicationsCommunicationIdDelete(requestData)
        },
        onSuccess: () => onSuccess(),
        onError: (error, variables, context) => {
            sendError(rollbar, 'Error deleting communication via api delete: ', [error, variables, context])
        },
    })
}
export const usePreviewCommunication = (
    {onSuccess}: {onSuccess?: () => void},
    {onError}: {onError?: (errorText: string) => void},
) => {
    const rollbar = useRollbar()

    return useMutation({
        mutationFn: (requestData: PreviewCommunicationRequest) => {
            return api.sendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPost(requestData)
        },
        onSuccess: () => onSuccess && onSuccess(),
        onError: (error: Response, variables, context) => {
            sendError(rollbar, 'Error previewing communication via api post: ', [error, variables, context])
            if (onError) {
                onError(error.statusText)
            }
        },
    })
}

export const useSubmitCommunication = ({onSuccess}: {onSuccess?: () => void}) => {
    const rollbar = useRollbar()

    return useMutation({
        mutationFn: (requestData: SubmitCommunicationRequest) => {
            return api.sendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPut(requestData)
        },
        onSuccess: () => onSuccess && onSuccess(),
        onError: (error, variables, context) => {
            sendError(rollbar, 'Error submitting communication via api put: ', [error, variables, context])
        },
    })
}

export const useCommunicationStats = ({communicationId}: {communicationId: string}) => {
    const {data} = useQuery(
        ['getCommunicationStats'],
        async () => {
            return api.getCommunicationStatsApiCommunicationsCommunicationIdStatsGet({communicationId})
        },
        {useErrorBoundary: true},
    )
    return data! // we can assert it's present because we use the error boundary if it's not
}
