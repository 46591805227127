import {useRollbar} from '@rollbar/react'
import * as React from 'react'
import {sendError} from '~src/rollbar'

interface FrameProps {
    url: string
    accesibleDescription: string
}

export const Frame = ({url, accesibleDescription}: FrameProps) => {
    const rollbar = useRollbar()
    const [contentHeight, setContentHeight] = React.useState(0)
    const iFrameRef = React.useRef<HTMLIFrameElement>(null)

    const determineHeight = () => {
        // max height of the navbar, offset used to hide the scrollbar
        const heightOffset = 150

        // wait for react to render
        setTimeout(() => {
            if (iFrameRef.current && iFrameRef.current.contentWindow) {
                try {
                    setContentHeight(
                        Math.max(
                            iFrameRef.current.contentWindow.document.body.scrollHeight,
                            window.innerHeight - heightOffset,
                        ),
                    )
                } catch (error) {
                    sendError(rollbar, 'Unable to set content height of iFrame', [error])
                }
            }
        }, 1000) // 1 second
    }

    return (
        <iframe
            title={accesibleDescription}
            src={url}
            sandbox="allow-scripts allow-downloads allow-popups allow-same-origin"
            width="100%"
            height={contentHeight}
            ref={iFrameRef}
            onLoad={determineHeight}
        />
    )
}
