import {Calendar, ChevronLeft, ChevronRight} from 'lucide-react'
import {DateTime} from 'luxon'
import moment from 'moment'
import * as React from 'react'
import {SingleDatePicker} from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import * as styles from './_assets/SingleDatePicker.module.scss'

interface DatePickerProps {
    value?: DateTime
    disabled?: boolean
    onChange: (value: moment.Moment) => void
}

export const DatePicker = ({value, disabled, onChange}: DatePickerProps) => {
    const [dateFocused, setDateFocused] = React.useState(false)

    return (
        <div className={styles.datePicker}>
            <SingleDatePicker
                screenReaderInputMessage=" " // this is to prevent the screen reader from reading the datepicker's default message
                hideKeyboardShortcutsPanel
                date={value ? moment(value.valueOf()) : null}
                onDateChange={(date: moment.Moment | null) => {
                    if (date) {
                        onChange(date)
                    }
                }}
                focused={dateFocused}
                onFocusChange={({focused}: {focused: boolean | null}) => setDateFocused(focused || false)}
                id="scheduleDate"
                placeholder="Select date"
                readOnly
                numberOfMonths={1}
                withPortal={document.documentElement.clientWidth < 758}
                displayFormat="DD/MM/YY"
                transitionDuration={0}
                isOutsideRange={date => {
                    const calendarDate = date.format('YYYY-MM-DD')
                    const yesterday = DateTime.local().minus({days: 1}).toISODate()
                    return yesterday! >= calendarDate
                }}
                navPrev={<ChevronLeft />}
                navNext={<ChevronRight />}
                customInputIcon={<Calendar size={16} color="#71717A" />}
                disabled={disabled}
            />
        </div>
    )
}
