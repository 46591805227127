import * as React from 'react'
import {useHealthCheck} from '~src/hooks/useHealthCheck'

export const Debug = () => {
    const backendHealthCheck = useHealthCheck()

    return (
        <div className="container">
            <p>Connection to company portal backend: {backendHealthCheck.status}</p>
        </div>
    )
}
