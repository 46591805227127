import {useAtom} from 'jotai'
import * as React from 'react'
import {Navigation} from '../../components/Navigation/Navigation'
import {useCurrentStaff} from '../../hooks/useCurrentStaff'
import {currentStaffAtom} from '~src/state/currentStaff'

export const NavigationLayout = () => {
    const [currentStaff, setCurrentStaff] = useAtom(currentStaffAtom)
    setCurrentStaff(useCurrentStaff())

    return currentStaff && <Navigation />
}
