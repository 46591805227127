import {useMutation} from '@tanstack/react-query'
import {LogoutResponse} from '~src/api'
import {CreateLogoutApiLogoutsPostRequest as CreateLogoutRequest, defaultApi as api} from '~src/api/apis'

interface UseCreateLogoutProps {
    onSuccess?: (data: LogoutResponse) => void
    onError?: (error: any, variable: any, context: any) => void
}

export const useCreateLogout = ({onSuccess, onError}: UseCreateLogoutProps) => {
    return useMutation({
        mutationFn: (requestData: CreateLogoutRequest) => {
            return api.createLogoutApiLogoutsPost(requestData)
        },
        onSuccess: data => onSuccess && onSuccess(data),
        onError: (error, variable, context) => onError && onError(error, variable, context),
    })
}
