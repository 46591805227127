/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface HealthCheckResponse
 */
export interface HealthCheckResponse {
    /**
     *
     * @type {string}
     * @memberof HealthCheckResponse
     */
    status: string
}

export function HealthCheckResponseFromJSON(json: any): HealthCheckResponse {
    return HealthCheckResponseFromJSONTyped(json, false)
}

export function HealthCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthCheckResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        status: json['status'],
    }
}

export function HealthCheckResponseToJSON(value?: HealthCheckResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        status: value.status,
    }
}
