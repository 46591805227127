@import '/src/_assets/styles/config.module';
@import '/src/_assets/styles/typography';

.strong {
    @include typography(small-text-strong);
}

.container {
    @include cardContainer;

    .containerHeader {
        display: flex;
        gap: 8px;
        align-items: center;
        align-self: stretch;
        padding-top: 24px;

        h3 {
            @include typography(body-text-strong);
        }

        p {
            @include typography(small-text);
        }
    }
}

.tableRowTitle {
    @include typography(small-text-strong);
}

.tableNoData {
    @include typography(body-text);

    .strong {
        @include typography(body-text-strong);
    }
}

.viewCommunication {
    padding: 0 12px 8px;

    .itemRow:not(:last-child) {
        padding-bottom: 24px;
        border-bottom: 1px solid #e4e4e7;
    }

    .itemName {
        @include typography(small-text-strong);
        padding-left: 0;
    }

    .itemValue {
        @include typography(small-text);
        padding-right: 0;
    }
}
