/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
    CompanyResponse,
    CompanyResponseFromJSON,
    CompanyResponseToJSON,
    CreateOrUpdateInvestorCommunicationRequest,
    CreateOrUpdateInvestorCommunicationRequestFromJSON,
    CreateOrUpdateInvestorCommunicationRequestToJSON,
    HTTPValidationError,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    HealthCheckResponse,
    HealthCheckResponseFromJSON,
    HealthCheckResponseToJSON,
    InvestorCommunicationResponse,
    InvestorCommunicationResponseFromJSON,
    InvestorCommunicationResponseToJSON,
    InvestorCommunicationStatsResponse,
    InvestorCommunicationStatsResponseFromJSON,
    InvestorCommunicationStatsResponseToJSON,
    LogoutResponse,
    LogoutResponseFromJSON,
    LogoutResponseToJSON,
    StaffResponse,
    StaffResponseFromJSON,
    StaffResponseToJSON,
} from '../models'

export interface CreateCommunicationApiCommunicationsPostRequest {
    createOrUpdateInvestorCommunicationRequest: CreateOrUpdateInvestorCommunicationRequest
    auth0Jwt?: string
}

export interface CreateLogoutApiLogoutsPostRequest {
    auth0Jwt?: string
}

export interface DeleteCommunicationApiCommunicationsCommunicationIdDeleteRequest {
    communicationId: string
    auth0Jwt?: string
}

export interface GetCommunicationStatsApiCommunicationsCommunicationIdStatsGetRequest {
    communicationId: string
    auth0Jwt?: string
}

export interface GetCompanyApiCompaniesCompanyIdGetRequest {
    companyId: string
    auth0Jwt?: string
}

export interface GetCurrentStaffApiStaffCurrentStaffGetRequest {
    auth0Jwt?: string
}

export interface GetStaffApiStaffStaffIdGetRequest {
    staffId: string
    auth0Jwt?: string
}

export interface SendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPutRequest {
    communicationId: string
    auth0Jwt?: string
}

export interface SendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPostRequest {
    communicationId: string
    auth0Jwt?: string
}

export interface UpdateCommunicationApiCommunicationsCommunicationIdPutRequest {
    communicationId: string
    createOrUpdateInvestorCommunicationRequest: CreateOrUpdateInvestorCommunicationRequest
    auth0Jwt?: string
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     * Auth0 Check
     */
    async auth0CheckAuth0CheckGetRaw(): Promise<runtime.ApiResponse<HealthCheckResponse>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('HTTPBearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/auth0-check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => HealthCheckResponseFromJSON(jsonValue))
    }

    /**
     * Auth0 Check
     */
    async auth0CheckAuth0CheckGet(): Promise<HealthCheckResponse> {
        const response = await this.auth0CheckAuth0CheckGetRaw()
        return await response.value()
    }

    /**
     * Create Communication
     */
    async createCommunicationApiCommunicationsPostRaw(
        requestParameters: CreateCommunicationApiCommunicationsPostRequest,
    ): Promise<runtime.ApiResponse<InvestorCommunicationResponse>> {
        if (
            requestParameters.createOrUpdateInvestorCommunicationRequest === null ||
            requestParameters.createOrUpdateInvestorCommunicationRequest === undefined
        ) {
            throw new runtime.RequiredError(
                'createOrUpdateInvestorCommunicationRequest',
                'Required parameter requestParameters.createOrUpdateInvestorCommunicationRequest was null or undefined when calling createCommunicationApiCommunicationsPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request({
            path: `/api/communications`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrUpdateInvestorCommunicationRequestToJSON(
                requestParameters.createOrUpdateInvestorCommunicationRequest,
            ),
        })

        return new runtime.JSONApiResponse(response, jsonValue => InvestorCommunicationResponseFromJSON(jsonValue))
    }

    /**
     * Create Communication
     */
    async createCommunicationApiCommunicationsPost(
        requestParameters: CreateCommunicationApiCommunicationsPostRequest,
    ): Promise<InvestorCommunicationResponse> {
        const response = await this.createCommunicationApiCommunicationsPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Create Logout
     */
    async createLogoutApiLogoutsPostRaw(
        requestParameters: CreateLogoutApiLogoutsPostRequest,
    ): Promise<runtime.ApiResponse<LogoutResponse>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/logouts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => LogoutResponseFromJSON(jsonValue))
    }

    /**
     * Create Logout
     */
    async createLogoutApiLogoutsPost(requestParameters: CreateLogoutApiLogoutsPostRequest): Promise<LogoutResponse> {
        const response = await this.createLogoutApiLogoutsPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Delete Communication
     */
    async deleteCommunicationApiCommunicationsCommunicationIdDeleteRaw(
        requestParameters: DeleteCommunicationApiCommunicationsCommunicationIdDeleteRequest,
    ): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError(
                'communicationId',
                'Required parameter requestParameters.communicationId was null or undefined when calling deleteCommunicationApiCommunicationsCommunicationIdDelete.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/communications/{communication_id}`.replace(
                `{${'communication_id'}}`,
                encodeURIComponent(String(requestParameters.communicationId)),
            ),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse<any>(response)
    }

    /**
     * Delete Communication
     */
    async deleteCommunicationApiCommunicationsCommunicationIdDelete(
        requestParameters: DeleteCommunicationApiCommunicationsCommunicationIdDeleteRequest,
    ): Promise<object> {
        const response = await this.deleteCommunicationApiCommunicationsCommunicationIdDeleteRaw(requestParameters)
        return await response.value()
    }

    /**
     * Get stats for an investor communication.  Will return previously fetched stats if not enough time has passed since the last fetch.
     * Get Communication Stats
     */
    async getCommunicationStatsApiCommunicationsCommunicationIdStatsGetRaw(
        requestParameters: GetCommunicationStatsApiCommunicationsCommunicationIdStatsGetRequest,
    ): Promise<runtime.ApiResponse<InvestorCommunicationStatsResponse>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError(
                'communicationId',
                'Required parameter requestParameters.communicationId was null or undefined when calling getCommunicationStatsApiCommunicationsCommunicationIdStatsGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/communications/{communication_id}/stats`.replace(
                `{${'communication_id'}}`,
                encodeURIComponent(String(requestParameters.communicationId)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => InvestorCommunicationStatsResponseFromJSON(jsonValue))
    }

    /**
     * Get stats for an investor communication.  Will return previously fetched stats if not enough time has passed since the last fetch.
     * Get Communication Stats
     */
    async getCommunicationStatsApiCommunicationsCommunicationIdStatsGet(
        requestParameters: GetCommunicationStatsApiCommunicationsCommunicationIdStatsGetRequest,
    ): Promise<InvestorCommunicationStatsResponse> {
        const response = await this.getCommunicationStatsApiCommunicationsCommunicationIdStatsGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Get Company
     */
    async getCompanyApiCompaniesCompanyIdGetRaw(
        requestParameters: GetCompanyApiCompaniesCompanyIdGetRequest,
    ): Promise<runtime.ApiResponse<CompanyResponse>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter requestParameters.companyId was null or undefined when calling getCompanyApiCompaniesCompanyIdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/companies/{company_id}`.replace(
                `{${'company_id'}}`,
                encodeURIComponent(String(requestParameters.companyId)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => CompanyResponseFromJSON(jsonValue))
    }

    /**
     * Get Company
     */
    async getCompanyApiCompaniesCompanyIdGet(
        requestParameters: GetCompanyApiCompaniesCompanyIdGetRequest,
    ): Promise<CompanyResponse> {
        const response = await this.getCompanyApiCompaniesCompanyIdGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Get Current Staff
     */
    async getCurrentStaffApiStaffCurrentStaffGetRaw(
        requestParameters: GetCurrentStaffApiStaffCurrentStaffGetRequest,
    ): Promise<runtime.ApiResponse<StaffResponse>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/staff/current-staff`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => StaffResponseFromJSON(jsonValue))
    }

    /**
     * Get Current Staff
     */
    async getCurrentStaffApiStaffCurrentStaffGet(
        requestParameters: GetCurrentStaffApiStaffCurrentStaffGetRequest,
    ): Promise<StaffResponse> {
        const response = await this.getCurrentStaffApiStaffCurrentStaffGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Get Staff
     */
    async getStaffApiStaffStaffIdGetRaw(
        requestParameters: GetStaffApiStaffStaffIdGetRequest,
    ): Promise<runtime.ApiResponse<StaffResponse>> {
        if (requestParameters.staffId === null || requestParameters.staffId === undefined) {
            throw new runtime.RequiredError(
                'staffId',
                'Required parameter requestParameters.staffId was null or undefined when calling getStaffApiStaffStaffIdGet.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/staff/{staff_id}`.replace(
                `{${'staff_id'}}`,
                encodeURIComponent(String(requestParameters.staffId)),
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => StaffResponseFromJSON(jsonValue))
    }

    /**
     * Get Staff
     */
    async getStaffApiStaffStaffIdGet(requestParameters: GetStaffApiStaffStaffIdGetRequest): Promise<StaffResponse> {
        const response = await this.getStaffApiStaffStaffIdGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Standard health check. Calls the database with a count query to make sure the database is connected
     * Health Check
     */
    async healthCheckHealthCheckGetRaw(): Promise<runtime.ApiResponse<HealthCheckResponse>> {
        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/health-check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => HealthCheckResponseFromJSON(jsonValue))
    }

    /**
     * Standard health check. Calls the database with a count query to make sure the database is connected
     * Health Check
     */
    async healthCheckHealthCheckGet(): Promise<HealthCheckResponse> {
        const response = await this.healthCheckHealthCheckGetRaw()
        return await response.value()
    }

    /**
     * Send Communication For Review
     */
    async sendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPutRaw(
        requestParameters: SendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPutRequest,
    ): Promise<runtime.ApiResponse<InvestorCommunicationResponse>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError(
                'communicationId',
                'Required parameter requestParameters.communicationId was null or undefined when calling sendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPut.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/communications/{communication_id}/send-for-review`.replace(
                `{${'communication_id'}}`,
                encodeURIComponent(String(requestParameters.communicationId)),
            ),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => InvestorCommunicationResponseFromJSON(jsonValue))
    }

    /**
     * Send Communication For Review
     */
    async sendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPut(
        requestParameters: SendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPutRequest,
    ): Promise<InvestorCommunicationResponse> {
        const response =
            await this.sendCommunicationForReviewApiCommunicationsCommunicationIdSendForReviewPutRaw(requestParameters)
        return await response.value()
    }

    /**
     * Send Communication Preview
     */
    async sendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPostRaw(
        requestParameters: SendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPostRequest,
    ): Promise<runtime.ApiResponse<InvestorCommunicationResponse>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError(
                'communicationId',
                'Required parameter requestParameters.communicationId was null or undefined when calling sendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPost.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request({
            path: `/api/communications/{communication_id}/preview`.replace(
                `{${'communication_id'}}`,
                encodeURIComponent(String(requestParameters.communicationId)),
            ),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => InvestorCommunicationResponseFromJSON(jsonValue))
    }

    /**
     * Send Communication Preview
     */
    async sendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPost(
        requestParameters: SendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPostRequest,
    ): Promise<InvestorCommunicationResponse> {
        const response =
            await this.sendCommunicationPreviewApiCommunicationsCommunicationIdPreviewPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * Update Communication
     */
    async updateCommunicationApiCommunicationsCommunicationIdPutRaw(
        requestParameters: UpdateCommunicationApiCommunicationsCommunicationIdPutRequest,
    ): Promise<runtime.ApiResponse<InvestorCommunicationResponse>> {
        if (requestParameters.communicationId === null || requestParameters.communicationId === undefined) {
            throw new runtime.RequiredError(
                'communicationId',
                'Required parameter requestParameters.communicationId was null or undefined when calling updateCommunicationApiCommunicationsCommunicationIdPut.',
            )
        }

        if (
            requestParameters.createOrUpdateInvestorCommunicationRequest === null ||
            requestParameters.createOrUpdateInvestorCommunicationRequest === undefined
        ) {
            throw new runtime.RequiredError(
                'createOrUpdateInvestorCommunicationRequest',
                'Required parameter requestParameters.createOrUpdateInvestorCommunicationRequest was null or undefined when calling updateCommunicationApiCommunicationsCommunicationIdPut.',
            )
        }

        const queryParameters: runtime.HTTPQuery = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request({
            path: `/api/communications/{communication_id}`.replace(
                `{${'communication_id'}}`,
                encodeURIComponent(String(requestParameters.communicationId)),
            ),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrUpdateInvestorCommunicationRequestToJSON(
                requestParameters.createOrUpdateInvestorCommunicationRequest,
            ),
        })

        return new runtime.JSONApiResponse(response, jsonValue => InvestorCommunicationResponseFromJSON(jsonValue))
    }

    /**
     * Update Communication
     */
    async updateCommunicationApiCommunicationsCommunicationIdPut(
        requestParameters: UpdateCommunicationApiCommunicationsCommunicationIdPutRequest,
    ): Promise<InvestorCommunicationResponse> {
        const response = await this.updateCommunicationApiCommunicationsCommunicationIdPutRaw(requestParameters)
        return await response.value()
    }
}
