.leftIcon {
    margin-right: 8px;
}

.rightIcon {
    margin-left: 8px;
}

.leftIcon,
.rightIcon {
    transform: translateY(-2px); // Button is slightly off so move it up
    transition: stroke 0.15s ease-in-out;
    stroke: var(--bs-btn-color);
}

.buttonWithIcon {
    position: relative;

    &:hover {
        .leftIcon,
        .rightIcon {
            stroke: var(--bs-btn-hover-color);
        }
    }

    &:active {
        .leftIcon,
        .rightIcon {
            stroke: var(--bs-btn-active-color);
        }
    }
}
