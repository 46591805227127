@import '../../../_assets/styles/config.module';

.navigation {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    padding: spacing(20) spacing(20) 0 0;

    .btn:not(.btnCheck) {
        min-width: spacing(40);
        margin-left: spacing(8);
        background-color: semanticColour(button-primary-default-background);
        border-color: semanticColour(button-primary-default-background);

        &:focus,
        &:active {
            @include hasAccess {
                @extend %focusOutlineWithRadius;
            }
            background-color: semanticColour(button-primary-active-background);
            border-color: semanticColour(button-primary-active-background);
        }

        &:hover {
            background-color: semanticColour(button-primary-hover-background);
            border-color: semanticColour(button-primary-hover-background);
        }
    }

    .heading {
        @extend %screenreaderOnly;
    }

    .list {
        @include breakpoint('tablet') {
            flex-direction: row;
        }
        display: flex;
        flex-direction: column;
        align-items: end;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .listItem {
        margin-left: spacing(8);
    }
}

.main {
    @include container();
    box-sizing: border-box;
}
