/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     *
     * @type {Array<string | number>}
     * @memberof ValidationError
     */
    loc: Array<string | number>
    /**
     *
     * @type {string}
     * @memberof ValidationError
     */
    msg: string
    /**
     *
     * @type {string}
     * @memberof ValidationError
     */
    type: string
}

export function ValidationErrorFromJSON(json: any): ValidationError {
    return ValidationErrorFromJSONTyped(json, false)
}

export function ValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationError {
    if (json === undefined || json === null) {
        return json
    }
    return {
        loc: (json['loc'] as Array<any>).map(string | numberFromJSON),
        msg: json['msg'],
        type: json['type'],
    }
}

export function ValidationErrorToJSON(value?: ValidationError | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        loc: (value.loc as Array<any>).map(string | numberToJSON),
        msg: value.msg,
        type: value.type,
    }
}
