import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react'
import React from 'react'
import {PineappleLoader} from '../PineappleLoader/PineappleLoader'

export const AuthenticationGuard = ({component}: any) => {
    const Component = withAuthenticationRequired(component, {})

    const {isAuthenticated, isLoading} = useAuth0()

    // Note that the Auth0 guard will only redirect you to the login screen if <Component />
    // is loaded, so do not block loading of <Component /> behind an authentication check
    return (
        <>
            {isLoading && !isAuthenticated && <PineappleLoader fullScreen />}
            {!isLoading && <Component />}
        </>
    )
}
