import {useAuth0} from '@auth0/auth0-react'
import {useRollbar} from '@rollbar/react'
import {useQueryErrorResetBoundary} from '@tanstack/react-query'
import * as React from 'react'
import {useCookies} from 'react-cookie'
import {ErrorBoundary} from 'react-error-boundary'
import {Routes, Route} from 'react-router-dom'
import Rollbar from 'rollbar'
import {AuthenticationGuard} from './components/AuthenticationGuard/AuthenticationGuard'
import {Error} from './components/Error/Error'
import {PineappleLoader} from './components/PineappleLoader/PineappleLoader'
import config from './config'
import {sendError} from './rollbar'
import {Communications} from './sections/Communications/Communications'
import {NewCommunication} from './sections/Communications/NewCommunication'
import {ViewCommunication} from './sections/Communications/ViewCommunication'
import {Debug} from './sections/Debug/Debug'
import {NotFound} from './sections/Error'
import {InvestorCohorts} from './sections/InvestorReports/InvestorCohorts'
import {InvestorReports} from './sections/InvestorReports/InvestorReports'
import {KitchenSink} from './sections/KitchenSink/KitchenSink'
import {BaseLayout} from './sections/Layout'
import * as styles from './App.module.scss'
import * as accessibilityStyles from './_assets/styles/accessibility.module.scss'
import './_assets/styles/global.scss' // Import root styles

const errorHandler = (rollbar: Rollbar, error: Error, info: {componentStack: string}) => {
    // send the error to rollbar
    sendError(rollbar, error, info)
}

export const App: React.FunctionComponent = () => {
    const rollbar = useRollbar()
    const {reset} = useQueryErrorResetBoundary()
    const {getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated} = useAuth0()
    const [cookies, setCookie] = useCookies(['auth0_jwt'])

    const body = document.body
    const accessClass = 'hasAccess'

    React.useEffect(() => {
        /*
         * By default, if a user logs out then back in within the lifetime of their Auth0 JWT
         * token they will be given the same token a second time by Auth0 as it's still valid.
         *
         * However, as a security measure, on logout we mark the token as invalid in our DB
         * and so they can't login with the re-issued token.
         *
         * To ensure that Auth0 always issues a new token to the user, we set cacheMode to false.
         */
        const cacheMode = 'off' // Auth0 default is 'on'

        const token = cookies.auth0_jwt
        if (isAuthenticated && !token) {
            // It's not possible to getAccessTokenSilently for localhost, so
            // we have to show a popup in development
            if (config.environment === 'development') {
                getAccessTokenWithPopup({
                    authorizationParams: {
                        audience: config.auth0Audience,
                        scope: 'read:current_user',
                    },
                    cacheMode,
                }).then(accessToken => {
                    setCookie('auth0_jwt', accessToken, {sameSite: true, maxAge: config.auth0TokenExpiration})
                })
            } else {
                getAccessTokenSilently({
                    authorizationParams: {
                        audience: config.auth0Audience,
                        scope: 'read:current_user',
                    },
                    cacheMode,
                }).then(accessToken => {
                    setCookie('auth0_jwt', accessToken, {sameSite: true, maxAge: config.auth0TokenExpiration})
                })
            }
        }
    }, [isAuthenticated])

    const handleKeyUp = (e: KeyboardEvent) => {
        const tabKey = e.key === 'Tab'
        if (!tabKey) {
            return
        }

        if (body) {
            // add keyboard accessibility features by default, used in scss files like
            // @include hasAccess { // my special rules for keyboard accessibility }
            body.classList.add(accessClass)
        }
    }

    const handleMouseClick = () => {
        if (body) {
            // remove focus outlines if the investor interacts with the page with a mouse
            body.classList.remove(accessClass)
        }
    }

    React.useEffect(() => {
        window.addEventListener('keyup', handleKeyUp)
        window.addEventListener('mousedown', handleMouseClick)

        return () => {
            window.removeEventListener('keyup', handleKeyUp)
            window.removeEventListener('mousedown', handleMouseClick)
        }
    }, [])

    return (
        <div className={styles.app}>
            <ul className={accessibilityStyles.accessibleLinks}>
                <li>
                    <a href="#navigation">skip to navigation</a>
                </li>
                <li>
                    <a href="#content">skip to content</a>
                </li>
            </ul>

            <ErrorBoundary
                key={location.pathname}
                onReset={reset} // button to retry
                FallbackComponent={error => (
                    // For now, the error page will just do a hard refresh
                    // Helps us get around the session expiration bug
                    <Error retryAction={() => window.location.reload()} error={error} />
                )}
                onError={(error, info) => errorHandler(rollbar, error, info)} // posting to rollbar
            >
                <React.Suspense fallback={<PineappleLoader fullScreen />}>
                    <Routes>
                        <Route path="/" element={<AuthenticationGuard component={BaseLayout} />}>
                            <Route index element={<Communications />} />
                            {/**Reports */}
                            <Route path="insights/shareholder-activity" element={<InvestorReports />} />
                            <Route path="insights/shareholder-breakdown" element={<InvestorCohorts />} />

                            {/**NEWER BOOTSTRAP VERSIONS */}
                            <Route path="communications" element={<Communications />} />
                            <Route path="communications/new" element={<NewCommunication />} />
                            <Route path="communications/:communicationId/edit" element={<NewCommunication />} />
                            <Route path="communications/:communicationId/view" element={<ViewCommunication />} />

                            <Route path="kitchen-sink" element={<KitchenSink />} />

                            {/*<Route path="log-out" element={<LogOut />} />*/}

                            <Route path="__debug__" element={<Debug />} />

                            <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    )
}
