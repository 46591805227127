import * as React from 'react'
import {Badge} from 'react-bootstrap'
import {InvestorCommunicationState} from '~src/api'

interface StatusBadgeProps {
    status: InvestorCommunicationState
}

export const StatusBadge = ({status}: StatusBadgeProps) => {
    switch (status) {
        case 'DRAFT':
            return <span className="badge border border-light text-dark rounded-pill">In draft</span>
        case 'PENDING_REVIEW':
            return (
                <Badge bg="light" text="dark" pill>
                    Pending review
                </Badge>
            )
        case 'APPROVED':
            return (
                <Badge bg="dark" pill>
                    Ready to send
                </Badge>
            )
    }
}
