import cn from 'classnames'
import {CalendarPlusIcon, MessageSquareDashed, SendIcon} from 'lucide-react'
import * as React from 'react'
import {Button, Table} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {HeroMetrics} from '../HeroMetrics/HeroMetrics'
import {StatusBadge} from './Common'
import * as styles from './Communications.module.scss'
import {InvestorCommunicationResponse, InvestorCommunicationState} from '~src/api'
import {ButtonWithIcon} from '~src/components/ButtonWithIcon/ButtonWithIcon'
import {useCurrentCompany} from '~src/hooks/useCurrentCompany'

type InvestorCommunicationStateOrder = {
    [key in InvestorCommunicationState]: number
}

const STATE_ORDER: InvestorCommunicationStateOrder = {
    DRAFT: 0,
    PENDING_REVIEW: 1,
    APPROVED: 2,
    SENT: 3,
    DELETED: -1, // we don't see these
}

export const Communications: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const company = useCurrentCompany()
    const investorComms = company.investor_communications

    const plannedComms = investorComms
        .filter(c => c.sent_at === undefined)
        .sort((a, b) => {
            if (a.state !== b.state) {
                return STATE_ORDER[a.state] - STATE_ORDER[b.state]
            }

            // most recently submitted on top
            if (a.state === 'PENDING_REVIEW') {
                return b.submitted_at!.toMillis() - a.submitted_at!.toMillis()
            }

            // closest to being sent at the bottom
            if (a.state === 'APPROVED') {
                return b.schedule_send_at.toMillis() - a.schedule_send_at.toMillis()
            }

            // most recently saved on top
            return b.last_saved_at.toMillis() - a.last_saved_at.toMillis()
        })

    const sentComms = investorComms
        .filter(c => c.sent_at !== undefined)
        .sort((a, b) => b.created_at.toMillis() - a.created_at.toMillis())

    return (
        <div className="container py-4 d-flex gap-3 flex-column">
            <div className="mb-3">
                <h1 className="h2">Welcome {company.name}</h1>
                {company.hero_metrics_list && (
                    <>
                        <h2 className="h5 mt-3 mb-0">Shareholder overview</h2>
                        <HeroMetrics />
                    </>
                )}
            </div>

            <h2 className="h5 mb-0">Communications</h2>
            <div className={cn(styles.container, 'd-flex gap-4 flex-column')}>
                <div className={cn(styles.containerHeader, plannedComms.length === 0 && 'mb-2')}>
                    <CalendarPlusIcon size={16} />
                    <h3 className="flex-grow-1 m-0">Planned</h3>
                    <Button size="sm" onClick={() => navigate('/communications/new')}>
                        Create new
                    </Button>
                </div>
                {plannedComms.length === 0 && <hr />}

                <CommunicationTable
                    comms={plannedComms}
                    showStatus
                    noData={
                        <>
                            <MessageSquareDashed className="mt-4" size={56} color="#23083133" opacity={0.8} />
                            <p className="mt-2 mb-4">
                                <span className={styles.strong}>You don’t have any planned communications</span>
                                <br />
                                <span className="mt-1">
                                    Once you schedule a communication, you’ll be able to view it here
                                </span>
                            </p>
                        </>
                    }
                />
            </div>

            {(sentComms.length > 0 || plannedComms.length > 0) && (
                <div className={cn(styles.container, 'd-flex gap-4 flex-column')}>
                    <div className={cn(styles.containerHeader, sentComms.length === 0 && 'mb-2')}>
                        <SendIcon size={16} />
                        <h3 className="flex-grow-1 m-0">Sent</h3>
                    </div>
                    {sentComms.length === 0 && <hr />}

                    <CommunicationTable
                        comms={sentComms}
                        noData={
                            <>
                                <MessageSquareDashed className="mt-4" size={56} color="#23083133" opacity={0.8} />
                                <p className="mt-2 mb-4">
                                    <span className={styles.strong}>You haven’t sent any communications yet</span>
                                    <br />
                                    <span className="mt-1">
                                        Once you’ve sent out your first communication, you’ll be able to view it here
                                    </span>
                                </p>
                            </>
                        }
                    />
                </div>
            )}
        </div>
    )
}

interface CommunicationTableProps {
    comms: InvestorCommunicationResponse[]
    showStatus?: boolean
    noData: JSX.Element
}

const CommunicationTable = ({comms, showStatus, noData}: CommunicationTableProps) => {
    const navigate = useNavigate()
    const onButtonClick = (comm: InvestorCommunicationResponse) => {
        return navigate(`/communications/${comm.id}/${comm.state === 'SENT' ? 'view' : 'edit'}`)
    }

    return (
        <>
            {comms.length > 0 ? (
                <Table responsive className="mb-1">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Date {showStatus ? 'to send' : 'sent'}</th>
                            {showStatus && <th>Status</th>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {comms.map(comm => (
                            <tr key={comm.id}>
                                <td className={styles.tableRowTitle}>{comm.title}</td>
                                <td>{comm.schedule_send_at.toLocal().toFormat('dd/MM/yyyy h:mm a').toLowerCase()}</td>
                                {showStatus && (
                                    <td>
                                        <StatusBadge status={comm.state} />
                                    </td>
                                )}
                                <td className="text-end">
                                    <ButtonWithIcon
                                        variant="ghost"
                                        size="sm"
                                        rightIcon="arrowRight"
                                        iconSize={16}
                                        onClick={() => onButtonClick(comm)}
                                    >
                                        {comm.state === 'DRAFT' ? 'Edit' : 'View'}
                                    </ButtonWithIcon>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <div className={cn(styles.tableNoData, 'text-center pb-2')}>{noData}</div>
            )}
        </>
    )
}
