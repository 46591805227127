import {Auth0Provider} from '@auth0/auth0-react'
import {Provider as RollbarProvider} from '@rollbar/react' // Provider imports 'rollbar'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {App} from './App'
import config from './config'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
        },
    },
})

const rollbarConfig = {
    accessToken: config.rollbarToken,
    environment: config.environment,
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <RollbarProvider config={rollbarConfig}>
        <React.StrictMode>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <Auth0Provider
                        domain={config.auth0Domain}
                        clientId={config.auth0ClientId}
                        authorizationParams={{
                            redirect_uri: window.location.origin,
                        }}
                    >
                        <App />
                    </Auth0Provider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </BrowserRouter>
        </React.StrictMode>
    </RollbarProvider>,
)
