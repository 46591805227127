import * as React from 'react'
import Button from 'react-bootstrap/Button'
import {FallbackProps} from 'react-error-boundary'
import pineappleLogo from '../../_assets/images/logos/pineapple-logo.svg'

interface ErrorProps {
    retryAction?: FallbackProps['resetErrorBoundary']
    error: FallbackProps['error']
}

export const Error = ({retryAction, error}: ErrorProps) => {
    const isAccessDenied = error.error.status === 403
    return (
        <div className="mx-auto mt-5 text-center">
            {isAccessDenied && (
                <>
                    <img src={pineappleLogo} className="sharesies-open-logo" alt="Sharesies Open" />
                    <h3 className="mt-2">Access Denied</h3>
                    <p>Sorry, your account doesn’t have access to that.</p>
                    <p>
                        If you are having issues with your account, please contact us on{' '}
                        <a href="mailto:companyservices@sharesies.co.nz">companyservices@sharesies.co.nz</a>.
                    </p>
                </>
            )}
            {!isAccessDenied && retryAction && (
                <>
                    <p className="mx-auto">Sorry... something went wrong :/</p>
                    <Button
                        className="mx-auto d-block"
                        onClick={() => {
                            retryAction()
                        }}
                    >
                        Try again
                    </Button>
                </>
            )}
        </div>
    )
}
