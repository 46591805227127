import {Editor as TinyEditor} from '@tinymce/tinymce-react'
import cn from 'classnames'
import * as React from 'react'
import {Editor as TinyMceEditor} from 'tinymce' // for ref type
import {PineappleLoader} from '../PineappleLoader/PineappleLoader'
import * as styles from './_assets/Editor.module.scss'
import config from '~src/config'

const MAX_IMAGE_SIZE = 5 // MB
const BYTES_PER_MB = 1000000

interface EditorProps {
    value: string
    forwardedRef: React.MutableRefObject<TinyMceEditor | null>
    disabled?: boolean
    onChange: (value: string) => void
}

/**
 * WYSIWYG Editor (What You See Is What You Get)
 *
 * @param {EditorProps} [props] - React props
 * @param {string} [props.value] - content of the editor
 * @param {React.MutableRefObject<TinyMceEditor | null>} [props.forwardedRef] - ref object to be used for the editor
 * @param {boolean|undefined} [props.disabled] - optional flag to disable the editor
 * @param {function(field: string, value: any, shouldValidate?: boolean): void} [props.onChange] - function to call on editor content change
 * @returns {React.ReactComponentElement} - component
 */
const Editor = ({value, forwardedRef, disabled, onChange}: EditorProps) => {
    const [isReady, setIsReady] = React.useState(false)

    // Short term change to make the loading of powerpaste controlled by config
    const activePlugins = [
        'advlist',
        'autolink',
        'autoresize', // add scrollbar only when editor reaches max height
        'charmap',
        'lists',
        'link',
        'table',
        'image',
    ]
    if (config.tinymceEnablePowerpaste) {
        activePlugins.push('powerpaste')
    }

    const enforceMaxImageWidth = (e: any) => {
        if (e && e.element.nodeName.toLowerCase() === 'img') {
            e.element.setAttribute('style', 'max-width: 504px;')
        }
    }

    return (
        <>
            {!isReady && <PineappleLoader fullScreen />}

            <div
                // avoid flash of unstyled textarea by hiding the editor until initialised
                className={cn({[styles.editorLoadingWrapper]: !isReady})}
            >
                <TinyEditor
                    id="editor"
                    tagName="content"
                    onEditorChange={(newValue, _editor) => {
                        onChange(newValue)
                    }}
                    apiKey={config.tinymceApiKey}
                    disabled={disabled}
                    onInit={(_evt, editor) => {
                        forwardedRef.current = editor
                        setIsReady(true)
                    }}
                    value={value}
                    onNodeChange={e => enforceMaxImageWidth(e)}
                    init={{
                        min_height: 300,
                        height: 300,
                        max_height: 600,
                        menubar: false,
                        statusbar: false,
                        skin: 'bootstrap',
                        icons: 'material',
                        placeholder: 'Message content',
                        plugins: activePlugins,
                        toolbar:
                            'undo redo blocks ' +
                            'bold italic underline link ' +
                            'align bullist numlist ' +
                            'subscript superscript ' +
                            'table image',
                        // updating the content style? check if you need to add elements or properties for inline styles in Editor.tsx
                        content_style:
                            `body { margin: 16px; font-family:inter,sans-serif; font-size:14px; ${
                                disabled && 'background-color: #E9ECEF;'
                            } }` +
                            'a { color: #EF496F; }' +
                            'p { margin: 24px 0 !important; }' +
                            'table, tr, td { border: 1px solid #EFEEF4 !important; }' +
                            'tr:first-of-type { background-color: #413E63; color: #FFFFFF; font-weight: 700; }' +
                            'tr:not(first-of-type) { background-color: #FFFFFF; } tr { padding: 8px !important; } ',
                        // Table settings
                        table_sizing_mode: 'relative', // Set width: 100% on tables
                        object_resizing: 'img', // Limit resizing to just images - don't allow tables to resize
                        table_toolbar:
                            'tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol', // Remove the table edit (tableprops) option from the toolbar
                        // IMAGE SETTINGS
                        images_file_types: 'jpeg, jpg, png',
                        file_picker_types: 'image',
                        block_unsupported_drop: true, // blocks unsupported file types from being dropped into the editor
                        image_dimensions: false, // force usage of image resizer to ensure the max width is respected
                        images_upload_handler: async blobInfo => {
                            return new Promise((resolve, reject) => {
                                const imageSize = blobInfo.blob().size / BYTES_PER_MB // MB
                                if (imageSize > MAX_IMAGE_SIZE) {
                                    return reject({
                                        message: `File is too large ${imageSize.toPrecision(
                                            3,
                                        )} MB, maximum image size is ${MAX_IMAGE_SIZE} MB`,
                                        remove: true,
                                    })
                                }

                                return resolve('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64())
                            })
                        },
                        // Powerpaste settings
                        paste_merge_formats: true, // This merges identical text format elements to reduce the number of HTML elements produced
                        powerpaste_word_import: 'clean', // Preserves the inline formatting and structure of the original document
                        powerpaste_googledocs_import: 'clean',
                        powerpaste_html_import: 'clean',
                        powerpaste_allow_local_images: true, // Base64 encoded images using a data URI in the copied content will not be removed after pasting
                    }}
                />
            </div>
        </>
    )
}

export default Editor
