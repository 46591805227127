@import '~@design-system/accessibility/accessibility';

.accessibleLinks {
    position: absolute;
    // for iframe rendering inside investor reports
    max-height: 64px;
    visibility: hidden;

    a {
        @extend %screenreaderOnly;
        visibility: visible;

        &:active,
        &:focus {
            @extend %screenreaderOnlyFocusable;
        }
    }
}
