/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {Exchange, ExchangeFromJSON, ExchangeFromJSONTyped} from './'

/**
 *
 * @export
 * @interface HeroMetricsResponse
 */
export interface HeroMetricsResponse {
    /**
     *
     * @type {string}
     * @memberof HeroMetricsResponse
     */
    total_shareholders: string
    /**
     *
     * @type {string}
     * @memberof HeroMetricsResponse
     */
    total_holdings: string
    /**
     *
     * @type {string}
     * @memberof HeroMetricsResponse
     */
    average_shareholding: string
    /**
     *
     * @type {string}
     * @memberof HeroMetricsResponse
     */
    average_buy_in_price: string
    /**
     *
     * @type {string}
     * @memberof HeroMetricsResponse
     */
    code: string
    /**
     *
     * @type {Exchange}
     * @memberof HeroMetricsResponse
     */
    exchange: Exchange
}

export function HeroMetricsResponseFromJSON(json: any): HeroMetricsResponse {
    return HeroMetricsResponseFromJSONTyped(json, false)
}

export function HeroMetricsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeroMetricsResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        total_shareholders: json['total_shareholders'],
        total_holdings: json['total_holdings'],
        average_shareholding: json['average_shareholding'],
        average_buy_in_price: json['average_buy_in_price'],
        code: json['code'],
        exchange: ExchangeFromJSON(json['exchange']),
    }
}

export function HeroMetricsResponseToJSON(value?: HeroMetricsResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        total_shareholders: value.total_shareholders,
        total_holdings: value.total_holdings,
        average_shareholding: value.average_shareholding,
        average_buy_in_price: value.average_buy_in_price,
        code: value.code,
        exchange: ExchangeToJSON(value.exchange),
    }
}
