/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface InvestorCommunicationStatsResponse
 */
export interface InvestorCommunicationStatsResponse {
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationStatsResponse
     */
    communication_id: string
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationStatsResponse
     */
    total_delivered: string
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationStatsResponse
     */
    total_unique_opens: string
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationStatsResponse
     */
    open_rate: string
}

export function InvestorCommunicationStatsResponseFromJSON(json: any): InvestorCommunicationStatsResponse {
    return InvestorCommunicationStatsResponseFromJSONTyped(json, false)
}

export function InvestorCommunicationStatsResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InvestorCommunicationStatsResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        communication_id: json['communication_id'],
        total_delivered: json['total_delivered'],
        total_unique_opens: json['total_unique_opens'],
        open_rate: json['open_rate'],
    }
}

export function InvestorCommunicationStatsResponseToJSON(value?: InvestorCommunicationStatsResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        communication_id: value.communication_id,
        total_delivered: value.total_delivered,
        total_unique_opens: value.total_unique_opens,
        open_rate: value.open_rate,
    }
}
