import cn from 'classnames'
import * as React from 'react'
import {Form} from 'react-bootstrap'
import * as styles from './_assets/TimeSelect.module.scss'

export const TIME_SELECT_OPTIONS = [
    {
        label: '8:00 AM',
        value: '08:00',
    },
    {
        label: '8:30 AM',
        value: '08:30',
    },
    {
        label: '9:00 AM',
        value: '09:00',
    },
    {
        label: '9:30 AM',
        value: '09:30',
    },
    {
        label: '10:00 AM',
        value: '10:00',
    },
    {
        label: '10:30 AM',
        value: '10:30',
    },
    {
        label: '11:00 AM',
        value: '11:00',
    },
    {
        label: '11:30 AM',
        value: '11:30',
    },
    {
        label: '12:00 PM',
        value: '12:00',
    },
    {
        label: '12:30 PM',
        value: '12:30',
    },
    {
        label: '1:00 PM',
        value: '13:00',
    },
    {
        label: '1:30 PM',
        value: '13:30',
    },
    {
        label: '2:00 PM',
        value: '14:00',
    },
    {
        label: '2:30 PM',
        value: '14:30',
    },
    {
        label: '3:00 PM',
        value: '15:00',
    },
    {
        label: '3:30 PM',
        value: '15:30',
    },
    {
        label: '4:00 PM',
        value: '16:00',
    },
    {
        label: '4:30 PM',
        value: '16:30',
    },
    {
        label: '5:00 PM',
        value: '17:00',
    },
]

interface TimeSelectProps {
    defaultValue?: string
    disabled?: boolean
    onChange: (value: string) => void
}

export const TimeSelect = ({defaultValue, disabled, onChange}: TimeSelectProps) => {
    const [localValue, setLocalValue] = React.useState(defaultValue || '')
    return (
        <Form.Select
            className={cn(styles.formSelectTime, localValue === '' && 'noSelection')}
            aria-label="Select time to send communication"
            defaultValue={defaultValue}
            onChange={event => {
                onChange(event.target.value)
                setLocalValue(event.target.value)
            }}
            disabled={disabled}
        >
            <option value="" className="default">
                Select time
            </option>
            {TIME_SELECT_OPTIONS.map(o => {
                return (
                    <option key={o.value} value={o.value}>
                        {o.label}
                    </option>
                )
            })}
        </Form.Select>
    )
}
