import cn from 'classnames'
import {SendIcon} from 'lucide-react'
import * as React from 'react'
import {Badge, Col, Row, Stack} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import * as styles from './Communications.module.scss'
import {InvestorCommunicationStatsResponse} from '~src/api'
import {ButtonWithIcon} from '~src/components/ButtonWithIcon/ButtonWithIcon'
import {Error} from '~src/components/Error/Error'
import {useCommunicationStats} from '~src/hooks/communications'
import {useCurrentCompany} from '~src/hooks/useCurrentCompany'
import {formatNumber} from '~src/util/formatNumber'

export const ViewCommunication = () => {
    const navigate = useNavigate()
    const {communicationId} = useParams()

    const investorComms = useCurrentCompany().investor_communications
    const communication = investorComms.find(c => c.id === communicationId)

    const communicationStats = useCommunicationStats({communicationId: communicationId!})
    const hasStatsDelivered = parseFloat(communicationStats.total_delivered) > 0

    if (!communication || !communication.sent_at) {
        return <Error />
    }

    const communicationItems = [
        {name: 'Sent on', value: communication.sent_at.toLocal().toFormat("dd MMMM yyyy 'at' h:mm a")},
        {name: 'Title/Subject line', value: communication.title},
        {name: 'Communication content', value: communication.content, is_html_content: true},
    ]

    return (
        <div className="container">
            <ButtonWithIcon
                className="mt-3 mb-1"
                variant="ghost"
                leftIcon="arrowLeft"
                iconSize={16}
                onClick={() => navigate(-1)}
            >
                Back
            </ButtonWithIcon>

            <h1 className="h3 mt-2 mb-0">{communication.title}</h1>

            {/** check the communicationId matches to prevent showing incorrect stats when switching between comms */}
            {hasStatsDelivered && communicationStats.communication_id === communicationId && (
                <EmailEngagementStats communicationStats={communicationStats} />
            )}

            <div className={cn(styles.container, 'd-flex gap-3 flex-column my-3')}>
                <div className={styles.containerHeader}>
                    <SendIcon size={16} />
                    <h2 className="flex-grow-1 m-0 h5">Communication details</h2>
                </div>
                <div className={styles.viewCommunication}>
                    {communicationItems.map((item, i) => (
                        <Row className={cn(styles.itemRow, 'mb-3')} key={i}>
                            <Col className={styles.itemName} xs={4}>
                                {item.name}
                            </Col>
                            <Col className={styles.itemValue}>
                                {!item.is_html_content && <>{item.value}</>}
                                {item.is_html_content && <div dangerouslySetInnerHTML={{__html: item.value}}></div>}
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        </div>
    )
}

const EmailEngagementStats = ({communicationStats}: {communicationStats: InvestorCommunicationStatsResponse}) => {
    const hasOpenRate = parseFloat(communicationStats.open_rate) > 0

    return (
        <Row>
            <Col xs={12} sm={6} className="mt-3">
                <div className={styles.container}>
                    <div className={styles.containerHeader}>
                        <p className={styles.strong}>Emails delivered</p>
                    </div>
                    <p className="h3 mb-3">
                        {formatNumber({number: communicationStats.total_delivered, decimalPlaces: 0})}
                    </p>
                </div>
            </Col>
            <Col xs={12} sm={6} className="mt-3">
                <div className={styles.container}>
                    <div className={styles.containerHeader}>
                        <p className={styles.strong}>Emails opened</p>
                    </div>
                    <Stack className="mb-2" direction="horizontal">
                        <p className="h3">
                            {formatNumber({
                                number: communicationStats.total_unique_opens,
                                decimalPlaces: 0,
                            })}{' '}
                            {hasOpenRate && (
                                <Badge className="align-middle ms-1" bg="light" text="dark" pill>
                                    {formatNumber({number: communicationStats.open_rate, decimalPlaces: 0})}% open rate
                                </Badge>
                            )}
                        </p>
                    </Stack>
                </div>
            </Col>
        </Row>
    )
}
