import {useQuery} from '@tanstack/react-query'
import {defaultApi as api} from '~src/api/apis'

export const useHealthCheck = () => {
    const {data} = useQuery(
        ['healthCheck'],
        () => {
            return api.healthCheckHealthCheckGet()
        },
        {useErrorBoundary: true},
    )
    return data! // we can assert it's present because we use the error boundary if it's not
}
