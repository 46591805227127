import React from 'react'
import Pineapple from '../../_assets/images/pineapple.svg'

interface LoadingProps {
    fullScreen?: boolean
}

export const PineappleLoader = ({fullScreen}: LoadingProps) => {
    let classNames
    if (fullScreen) {
        classNames = 'pineapple-loader-center'
    }
    return (
        <div className={classNames}>
            <img className="pineapple-svg" width="100" src={Pineapple} alt="loading..." />
        </div>
    )
}
