/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    HeroMetricsResponse,
    HeroMetricsResponseFromJSON,
    HeroMetricsResponseFromJSONTyped,
    InstrumentResponse,
    InstrumentResponseFromJSON,
    InstrumentResponseFromJSONTyped,
    InvestorCommunicationResponse,
    InvestorCommunicationResponseFromJSON,
    InvestorCommunicationResponseFromJSONTyped,
    StaffResponse,
    StaffResponseFromJSON,
    StaffResponseFromJSONTyped,
    SubscriptionResponse,
    SubscriptionResponseFromJSON,
    SubscriptionResponseFromJSONTyped,
} from './'

/**
 *
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     *
     * @type {string}
     * @memberof CompanyResponse
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof CompanyResponse
     */
    logo_url: string
    /**
     *
     * @type {Array<InstrumentResponse>}
     * @memberof CompanyResponse
     */
    instruments: Array<InstrumentResponse>
    /**
     *
     * @type {Array<StaffResponse>}
     * @memberof CompanyResponse
     */
    staff: Array<StaffResponse>
    /**
     *
     * @type {Array<SubscriptionResponse>}
     * @memberof CompanyResponse
     */
    subscriptions: Array<SubscriptionResponse>
    /**
     *
     * @type {Array<InvestorCommunicationResponse>}
     * @memberof CompanyResponse
     */
    investor_communications: Array<InvestorCommunicationResponse>
    /**
     *
     * @type {Array<HeroMetricsResponse>}
     * @memberof CompanyResponse
     */
    hero_metrics_list: Array<HeroMetricsResponse>
}

export function CompanyResponseFromJSON(json: any): CompanyResponse {
    return CompanyResponseFromJSONTyped(json, false)
}

export function CompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        name: json['name'],
        logo_url: json['logo_url'],
        instruments: (json['instruments'] as Array<any>).map(InstrumentResponseFromJSON),
        staff: (json['staff'] as Array<any>).map(StaffResponseFromJSON),
        subscriptions: (json['subscriptions'] as Array<any>).map(SubscriptionResponseFromJSON),
        investor_communications: (json['investor_communications'] as Array<any>).map(
            InvestorCommunicationResponseFromJSON,
        ),
        hero_metrics_list: (json['hero_metrics_list'] as Array<any>).map(HeroMetricsResponseFromJSON),
    }
}

export function CompanyResponseToJSON(value?: CompanyResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        name: value.name,
        logo_url: value.logo_url,
        instruments: (value.instruments as Array<any>).map(InstrumentResponseToJSON),
        staff: (value.staff as Array<any>).map(StaffResponseToJSON),
        subscriptions: (value.subscriptions as Array<any>).map(SubscriptionResponseToJSON),
        investor_communications: (value.investor_communications as Array<any>).map(InvestorCommunicationResponseToJSON),
        hero_metrics_list: (value.hero_metrics_list as Array<any>).map(HeroMetricsResponseToJSON),
    }
}
