/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {PlanResponse, PlanResponseFromJSON, PlanResponseFromJSONTyped} from './'

/**
 *
 * @export
 * @interface SubscriptionResponse
 */
export interface SubscriptionResponse {
    /**
     *
     * @type {string}
     * @memberof SubscriptionResponse
     */
    id: string
    /**
     *
     * @type {PlanResponse}
     * @memberof SubscriptionResponse
     */
    plan: PlanResponse
    /**
     *
     * @type {boolean}
     * @memberof SubscriptionResponse
     */
    is_active: boolean
    /**
     *
     * @type {Date}
     * @memberof SubscriptionResponse
     */
    created_at: DateTime
}

export function SubscriptionResponseFromJSON(json: any): SubscriptionResponse {
    return SubscriptionResponseFromJSONTyped(json, false)
}

export function SubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        plan: PlanResponseFromJSON(json['plan']),
        is_active: json['is_active'],
        created_at: DateTime.fromISO(json['created_at'], {setZone: true}),
    }
}

export function SubscriptionResponseToJSON(value?: SubscriptionResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        plan: PlanResponseToJSON(value.plan),
        is_active: value.is_active,
        created_at: value.created_at.toJSDate().toISOString(),
    }
}
