/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum Exchange {
    NZX = 'NZX',
    ASX = 'ASX',
}

export function ExchangeFromJSON(json: any): Exchange {
    return ExchangeFromJSONTyped(json, false)
}

export function ExchangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Exchange {
    return json as Exchange
}

export function ExchangeToJSON(value?: Exchange | null): any {
    return value as any
}
