const defaultConfig = {
    environment: 'development',
    auth0ClientId: 'b0SA3p0DtOyMbIOpN6CapaWrkoQar2Di',
    auth0Domain: 'sharesies-dev.au.auth0.com',
    auth0Audience: 'http://localhost:8100/',
    auth0TokenExpiration: 86400, // 24 hours, must match Token Expiration (Seconds) in Auth0
    tinymceApiKey: '36n1es1aeweq39th0q2eq3vyl2bmw10l3b0h8s52lfn0lnd2',
    rollbarToken: '',
    tinymceEnablePowerpaste: true,
}

const stagingConfig: typeof defaultConfig = {
    ...defaultConfig,
    environment: 'staging',
    auth0ClientId: 'm12WvvEtiNlqI1zBnPdHub8KcIhCFfNz',
    auth0Domain: 'sharesies-staging.au.auth0.com',
    auth0Audience: 'https://company-portal-staging.opsies.net.nz/',
    rollbarToken: 'b2da3ebb47ba4a5da93d54c51b073f30',
    tinymceEnablePowerpaste: true,
}

const productionConfig: typeof defaultConfig = {
    ...defaultConfig,
    environment: 'production',
    auth0ClientId: '2FncokhFngyH9FlB48nslmwliSzo13MC',
    auth0Domain: 'login.open.sharesies.com',
    auth0Audience: 'https://open.sharesies.com/',
    rollbarToken: 'b2da3ebb47ba4a5da93d54c51b073f30',
    tinymceEnablePowerpaste: true,
}

const config = (() => {
    if (location.host.includes('company-portal-staging.opsies.net.nz')) {
        return stagingConfig
    }

    if (location.host.includes('open.sharesies.com')) {
        return productionConfig
    }

    return defaultConfig
})()

export default config
