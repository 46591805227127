@import '/src/_assets/styles/typography';

// Start react-dates overrides
/* stylelint-disable selector-class-pattern */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable plugin/selector-tag-no-without-class */
.datePicker {
    :global(.DateInput) {
        @include typography(small-text);
        vertical-align: middle;
    }

    :global(.SingleDatePicker),
    :global(.SingleDatePickerInput),
    :global(.DateInput),
    :global(.DateInput_input) {
        z-index: var(--date-picker-z-index);
        margin: 0;
        padding: 0;
        background-color: transparent;
    }

    :global(.SingleDatePickerInput) {
        width: 250px;
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
    }

    :global(.SingleDatePickerInput:has(:focus-visible)) {
        border-radius: 6px;
        // prettier-ignore
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #3a81ff;
    }

    :global(.SingleDatePickerInput__disabled) {
        background-color: var(--date-picker-disabled-bg-color, #e9ecef);
    }

    :global(.DateInput_input) {
        @include typography(small-text);
        height: 40px;
        color: var(--bs-body-color);
        background-color: transparent;
        cursor: pointer;
    }

    :global(.DateInput_input__disabled) {
        font-style: unset;
    }

    :global(.DateInput_input__focused) {
        border-color: transparent;
    }

    :global(.SingleDatePickerInput_calendarIcon) {
        margin: 0;
        padding: 2px 8px 8px 16px;
    }

    :global(.SingleDatePickerInput_calendarIcon:focus-visible) {
        outline: none;
    }

    :global(.DayPickerNavigation) {
        position: absolute;
        top: 20px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        padding: 4px 16px;
    }

    :global(.DayPickerNavigation_button) {
        position: static;
        top: 0;
    }

    :global(.DateInput_fang) {
        display: none;
    }

    :global(.CalendarMonth_caption strong) {
        @include typography(h5);
    }

    :global(.DayPicker_weekHeader_li small) {
        @include typography(tiny-text);
    }

    :global(.CalendarDay) {
        @include typography(small-text);
    }

    :global(.CalendarDay:not(.CalendarDay__blocked_out_of_range):focus) {
        border-radius: 2px;
    }

    :global(.CalendarDay__selected),
    :global(.CalendarDay__selected:hover),
    :global(.CalendarDay__selected:focus) {
        color: var(--calendar-day-color, #ffffff);
        background-color: var(--calendar-day-bg-color, #000000);
        border-color: var(--calendar-day-border-color, #000000);
    }
}
/* stylelint-enable selector-class-pattern */
/* stylelint-enable no-descending-specificity */
/* stylelint-enable plugin/selector-tag-no-without-class */
// End react-dates overrides
